import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { CreateItem } from '../../utils/list.service'
import { getUserInfo } from '../../utils/user.service'

class MakeItemCreateInput extends React.Component {

  constructor(props) {
    super(props); 
    this.state = {
      user: getUserInfo(),
      newItem: ''
    }
  }

  handleChange = (evt) => {
    this.setState({
      newItem: evt.target.value
    });
  }

  // Create enter on ENTER input 
  handleKeyDown = (event) => {
    if (event.which !== 13 ) { return; }
    event.preventDefault();
    const val = this.state.newItem.trim();
    if (val) {
      CreateItem(val).then(result => {
        // send result to parent 
        this.props.onCreate(result)
        this.setState({ newItem: '' })
      }).catch(err=> console.warn(err))
    }
  }

  render() {
    // Only show if current user is owner of this list
    if (!this.state.user || (this.state.user.slug !== this.props.userSlug)) {
      return null;
    }

    return (
      <CreateInput 
        onKeyDown={ this.handleKeyDown }
        value={this.state.newItem}
        placeholder="What are you going to make?"
        onChange = {this.handleChange}
      />
    )
  }
}

const CreateInput = styled.input`
  ${tw`relative w-full border-0 m-0 p-4 h-16 text-xl text-gray-600 italic leading-tight shadow-inner`}
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`

export default MakeItemCreateInput; 