import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { Link } from 'gatsby'
import ToggleButton from './MakeItemToggleButton'

class ToMakeItem extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      userSlug: props.userSlug,
      makeItem: props.tomake,
      loading: false
    }
  }

  handleToggle = (e) => {
    console.log('handleToggle',e.done)
    this.setState(prevState => ({
      makeItem: {
        ...prevState.makeItem, 
        done: e.done
      }
    }))
    console.log(this.state.makeItem)
  }

  render() {
    const { makeItem } = this.state; 
    
    return (
      <ListItem completed={makeItem.done}>
        <Inner>
          <ToggleButton 
            userSlug={this.state.userSlug}
            makeItem={this.state.makeItem}
            onToggle={this.handleToggle}
          />
          <Label 
            checked={makeItem.done} 
            to={`/item/${makeItem.slug}`}
            style={{wordBreak: 'break-all'}}
          >{makeItem.title}</Label>
          <ItemLink 
            to={`/item/${makeItem.slug}`} 
          >&rarr;</ItemLink>
        </Inner>
      </ListItem>
    )
  }
}

export default ToMakeItem 

const ListItem = styled.li`${tw`text-xl`};
  border-bottom: 1px solid #efefef;
`  
const Inner = styled.div`
  ${tw`flex hover:bg-orange-100`};
  align-items: center;
  justify-content: center;
`
const Label = styled(Link)`
  ${tw`flex-1 p-4 no-underline text-black break-all`};
  color: ${props => {
    if (props.checked) { return '#aaa' }
  }};
  text-decoration: ${props => {
    if (props.checked) { return 'line-through' }
  }};
  transition: color 0.4s;
`
const ItemLink = styled(Link)`${tw`text-sm no-underline text-black p-4 h-full text-bold`}`