import React from 'react'
import { navigate } from 'gatsby'
// import styled from 'styled-components'
// import tw from 'tailwind.macro'
import Layout from '../components/shared/layout'
import SEO from '../components/shared/seo'
import { FetchList } from '../utils/list.service'
import ListPageComponent from '../components/list/ListPageComponent'
import Container from '../components/shared/Container'

class ListPage extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      listSlug: null,
      list: null
    }
  }

  // Fetch url param from location path 
  fetchParam(pathname) {
    return pathname.split('/').filter(Boolean).pop()
  }

  // Fetch toMake item by slug 
  fetchList(listSlug) {
    this.setState({ loading: true })
    FetchList(listSlug).then(list => {
      this.setState({list: list})
    }).catch(err => {
      console.warn(err)
      this.setState({err: err})
    }).finally(() => {
      this.setState({ loading: false })
    })
  }

  // Get the item after component mount 
  componentDidMount() {
    const p = this.props.location.pathname
    const s = this.fetchParam(p); 
    this.setState({
      listSlug: s
    })
    this.fetchList(s);
  }

  render() {
    const { location } = this.props; 

    if (!location) { navigate('/'); }
    const listSlug = this.fetchParam(location.pathname);

    const title = () => {
      if (this.state.listSlug) { return this.state.listSlug}
      else { return 'itms'}
    }
    
    return (
      <Layout>

        <SEO title={title()} />
        
        <Container>
          { this.state.loading && (<p>Loading list</p>)}
          { !this.state.loading && !this.state.list && (
            <p>List <em>{listSlug}</em> not found</p>
          )}
          { !this.state.loading && this.state.list && (
            <ListPageComponent slug={this.state.listSlug} items={this.state.list} />
          )}

        </Container>
      </Layout>
    )
  }    
}

export default ListPage; 
