import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import MakeItemListItem from '../../makeItem/MakeItem'
import MakeItemCreateInput from '../../makeItem/MakeItemCreateInput'

class ListPageComponent extends React.Component {

  constructor(props) {
    super(props); 
    this.state = {
      slug: props.slug,
      items: props.items
    }
  }

  // Update view with new item
  handleCreate = (newItem) => {
    this.setState(state => {
      const items = [newItem, ...state.items];
      return { items };
    });
  }
  
  // Update current view with updated item 
  handleToggle = (updatedItem) => { 
    this.setState(state => {
      const items = state.items.map((existingItem) => {
        if (existingItem.id === updatedItem.id) {
          existingItem.done = updatedItem.done; 
        } 
        return existingItem;
      });
      return { items };
    });
  }

  render() {
    if (!this.state.items) { return null}
    
    return (
      <div>
        
        <PageHeader>
          <Title>MAKE101 List for</Title>
          <Subtitle>{this.state.slug}</Subtitle>
        </PageHeader>

        <MakeItemCreateInput 
          userSlug={this.state.slug}
          onCreate={this.handleCreate}
        />
        
        <ToMakeList> 
          { this.state.items.length <1 && (
            <Empty>No items yet!</Empty>
          )}
          { this.state.items.map((tomake, index) => (
            <MakeItemListItem
              key={index} 
              tomake={ tomake }
              userSlug={this.state.slug}
              onToggle={ this.handleToggle }
            />
          )) } 
        </ToMakeList>
      </div>
    )
  }
}

const PageHeader = styled.div`${tw`text-center py-16`}`
const Title = styled.h2`${tw``}`
const Subtitle = styled.h1`${tw`text-orange-500`}`
const Empty = styled.div`${tw`text-center text-xl pb-16`}`
const ToMakeList = styled.ul`${tw`list-none p-0 m-0`}`

export default ListPageComponent