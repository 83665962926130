import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { getUserInfo } from '../../utils/user.service'
import { ToggleItem } from '../../utils/list.service'

class ToggleButton extends React.Component {

  constructor(props) {
    super(props); 
    this.state = {
      userSlug: props.userSlug,
      user: getUserInfo(),
      makeItem: props.makeItem
    }
  }

  handleToggle = (evt) => {
    evt.preventDefault()
    
    ToggleItem(this.state.makeItem.id, !this.state.makeItem.done)
      .then(result => {
        console.log('Tottle Item resutl', result.done)
        this.setState({ makeItem: result})
        this.props.onToggle(result)
      })
      .catch(err => { console.warn(err); });
  }

  render() {
    // Only show if current user is owner of this list
    if (!this.state.user || (this.state.user.slug !== this.props.userSlug)) {
      return null;
    }
    return (
      <ToggleBtn onClick={this.handleToggle}>
        {this.state.makeItem.done && (<img alt="done" src="data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2240%22%20height%3D%2240%22%20viewBox%3D%22-10%20-18%20100%20135%22%3E%3Ccircle%20cx%3D%2250%22%20cy%3D%2250%22%20r%3D%2250%22%20fill%3D%22none%22%20stroke%3D%22%23bddad5%22%20stroke-width%3D%223%22/%3E%3Cpath%20fill%3D%22%235dc2af%22%20d%3D%22M72%2025L42%2071%2027%2056l-4%204%2020%2020%2034-52z%22/%3E%3C/svg%3E" />)}
        {!this.state.makeItem.done && (<img alt="not done" src="data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2240%22%20height%3D%2240%22%20viewBox%3D%22-10%20-18%20100%20135%22%3E%3Ccircle%20cx%3D%2250%22%20cy%3D%2250%22%20r%3D%2250%22%20fill%3D%22none%22%20stroke%3D%22%23ededed%22%20stroke-width%3D%223%22/%3E%3C/svg%3E" />)}
      </ToggleBtn>
    )
  }
}

const ToggleBtn = styled.div`${tw`cursor-pointer`}`

export default ToggleButton